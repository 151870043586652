<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 3, showScore ? 5 : 6)" class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0" v-if="showTeamOneName">
            <b-card-title v-bind="getColumnProps('team1')" v-bind:title-tag="titleTag" v-if="showTeamOneName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team1')" v-if="isHeadline">{{ getHeadline('team1', $tc('team', 1) + ' 1') }}</label>
                <span class="text-uppercase" v-else>{{ match.teamOneName }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('score', 4, 2, 1)" class="align-self-center" v-if="showScore">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('score')" v-if="isHeadline">{{ getHeadline('score', $t('score')) }}</label>
            <span class="d-block text-uppercase" v-else-if="match.teamOneScore !== null && match.teamTwoScore !== null">{{ match.teamOneScore }} : {{ match.teamTwoScore }}</span>
            <span class="d-block text-uppercase" v-else>{{ $t('vs') }}</span>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5, showScore ? 5 : 6)" class="d-flex flex-column flex-lg-row align-items-center flex-grow-1 flex-basis-0 p-0" v-if="showTeamTwoName">
            <b-card-title v-bind="getColumnProps('team2')" v-bind:title-tag="titleTag" v-if="showTeamTwoName">
                <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('team2')" v-if="isHeadline">{{ getHeadline('team2', $tc('team', 1) + ' 2') }}</label>
                <span class="text-uppercase" v-else>{{ match.teamTwoName }}</span>
            </b-card-title>
        </div>

        <b-card-text v-bind="getColumnProps('kickOffTime', 12, 6, 2)" v-if="showKickOffTime">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('kickOffTime')">{{ getHeadline('kickOffTime', $t('kickOff')) }}</label>
            <span v-if="!isHeadline">{{ $fd(match.kickOff, 'dateTime') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('position', 12, 6, 1)" v-if="showPosition">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('position')">{{ getHeadline('position', $t('position')) }}</label>
            <span v-if="!isHeadline">{{ match.position }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('disciplinary', 12, 6, 2)" v-if="showDisciplinary" class="text-center">
            <label v-bind:class="headlineClasses">{{ getHeadline('disciplinary', $t('disciplinary')) }}</label>
            <span v-if="!isHeadline" class="text-center">
                <span
                    class="btn btn-icon no-click"
                    v-bind:class="buttonClasses"
                    v-if="match.yellowCard || match.redCard"
                >
                    <icon v-bind:type="disciplinaryIcon" large/>
                </span>
                <span
                    class="btn btn-icon no-click"
                    v-bind:class="buttonClasses"
                    v-if="match.warning"
                >
                    <icon type="warning" large/>
                </span>
                <span
                    class="btn btn-icon no-click"
                    v-bind:class="buttonClasses"
                    v-if="match.citing"
                >
                    <icon type="citing" large/>
                </span>
                <span v-if="!match.yellowCard && !match.redCard && !match.citing && !match.warning">
                    {{ $t('none') }}
                </span>
            </span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('select', 3, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="showSelect">
            <actionButton
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </actionButton>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, showWorkflowStatus ? 6 : 12, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('view', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('add', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:to="moveTo"
                v-on:click="emitEvent('move', $event)"
                v-if="showMove"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 6)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';

import RoleMatchModel from '@/models/roleMatch.js';

/**
 * raw match block component
 */
export default {
    name: 'roleMatchRow',
    extends: base,
    props: {
        match: {
            type: RoleMatchModel,
            required: false,
            default(){
                return new RoleMatchModel();
            }
        },

        showTeamOneName: {
            type: Boolean,
            default: true,
        },
        showTeamTwoName: {
            type: Boolean,
            default: true,
        },
        showPosition: {
            type: Boolean,
            default: false,
        },
        showScore: {
            type: Boolean,
            default: false,
        },
        showKickOffTime: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        showDisciplinary: {
            type: Boolean,
            default: false,
        },
        showWarning: {
            type: Boolean,
            default: false,
        },
        showCiting: {
            type: Boolean,
            default: false,
        },
        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },

        /**
         * show button to select item (used for picker)
         */
        showSelect: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.match.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                match: this.match || null,
            };
        },
        customElementClasses(){
            return {
                matchRow: true,
            };
        },
        hasVisibleIcons(){
            return (this.showDisciplinary || this.showWarning || this.showCiting);
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showAdd || this.showRemove || this.showMove);
        },
        disciplinaryIcon() {;
            if (this.match.redCard > 0) {
                if (this.match.yellowCard > 0) {
                    return 'cards';
                }
                return 'card-red';
            }

            if (this.match.yellowCard > 0) {
                return 'card-yellow';
            }

            return 'card-none';
        },
    },
};
</script>
