<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('venueName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('venueName')" v-if="isHeadline">{{ getHeadline('venueName', $t('venueName')) }}</label>
            <span v-else>{{ venue.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('country', 12, 6, 2)" v-if="showCountry">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('country')">{{ getHeadline('country', $t('country')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ venue.countryName || (venue.place ? venue.place.countryName : '') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('place', 12, 6, 2)" v-if="showPlace">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('place')">{{ getHeadline('place', $t('city')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ venue.locationName || (venue.place ? venue.place.name : '') }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('select', 3, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="showSelect">
            <actionButton
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </actionButton>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', venue.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-on:click="emitEvent('view', $event)"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', venue.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: venue.name })"
            v-bind:data="venue"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from './base.vue';

import VenueModel from '@/models/venue.js';

/**
 * team block component
 */
export default {
    name: 'venueRow',
    extends: base,
    props: {
        venue: {
            type: VenueModel,
            default(){
                return new VenueModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showCountry: {
            type: Boolean,
            default: false,
        },
        showPlace: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },

        /**
         * show button to select item (used for picker)
         */
        showSelect: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonVenueDetail',
                params: Object.assign({
                    dataVenueId: this.venue.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                venue: this.venue || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove);
        },
    },
};
</script>
