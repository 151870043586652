<template>
    <wrapper v-bind:title="title" v-bind:waitFor="waitFor" v-bind:waitForRetry="waitForRetry" class="detailHeader"
        largeTitle>
        <template v-slot:buttons>
            <actionButton variant="outline-primary" class="btn-flat" v-on:click="printSegment()">
                {{ $t('print') }}
            </actionButton>
        </template>
        <template v-slot:default>
            <div id="match-report-header" class="col-12 px-3 px-lg-5 py-4 py-lg-6" v-if="data">
                <div class="top col-12 d-flex flex-column align-items-center">
                    <div class="col-12 px-0 text-center">
                        <ul>
                            <li class="col-12 pd-b8" v-if="data.match.tournaments[0]">
                                <span>
                                    <b-img v-bind:src="data.match.tournaments[0].getImgPath()" v-bind:width="32"
                                        v-bind:height="32"></b-img>
                                </span>
                                <span class="m-0">{{ data.match.tournaments[0].name }} - {{ $tc('match', 1) }} {{
                                    data.match.tournaments[0].matchNumber }}</span>
                            </li>
                            <li class="col-12 no-padding">
                                <span class="m-0">{{ $fd(data.match.kickOff, 'dateTime') }}</span>
                            </li>
                            <li class="col-12">
                                <div class="no-print pb-3">
                                    <span>{{ data.match.venueName }}</span>
                                    <span>{{ data.match.locationName }}</span>
                                </div>
                                <div class="no-screen">
                                    <span>
                                        <strong>{{ data.match.venueName }}</strong>
                                        <span v-html="'&emsp;'" /> {{ data.match.locationName }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex flex-row gap-85">
                        <div class="d-flex flex-row align-items-center">
                            <div>
                                <h2 class="text-uppercase text-right m-0 pr-md-2 pt-1">{{ data.teams[0].team.name }}
                                </h2>
                            </div>
                            <span>
                                <b-img v-bind:src="data.match.getImgPathTeamOne()" v-bind:widht="48"
                                    v-bind:height="48"></b-img>
                            </span>
                        </div>
                        <div>
                            <span>{{ data.teams[0].team.score }} : {{ data.teams[1].team.score }}</span><br>
                            <span>({{ data.teams[0].team.firstHalfScore }} : {{ data.teams[1].team.firstHalfScore
                                }})</span>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <span>
                                <b-img v-bind:src="data.match.getImgPathTeamTwo()" v-bind:widht="48"
                                    v-bind:height="48"></b-img>
                            </span>
                            <div>
                                <h2 class="text-uppercase m-0 pl-md-2 pt-1">{{ data.teams[1].team.name }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row gap-100 pt-4">
                        <table class="w-100 no-border">
                            <tr>
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('headCoach') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamOneHeadCoach }}</span></td>
                            </tr>
                            <tr v-if="teamOneTeamDoctor || teamTwoTeamDoctor">
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('teamDoctor') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamOneTeamDoctor }}</span></td>
                            </tr>
                            <tr v-if="teamOneTeamManager || teamTwoTeamManager">
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('teamManager') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamOneTeamManager }}</span></td>
                            </tr>
                        </table>
                        <table class="w-100 no-border">
                            <tr>
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('headCoach') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamTwoHeadCoach }}</span></td>
                            </tr>
                            <tr v-if="teamTwoTeamDoctor || teamOneTeamDoctor">
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('teamDoctor') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamTwoTeamDoctor }}</span></td>
                            </tr>
                            <tr v-if="teamTwoTeamManager || teamOneTeamManager">
                                <td class="left-col"><span class="fieldKey no-wrap">{{ $t('teamManager') }}</span></td>
                                <td class="right-col"><span class="no-wrap">{{ displayTeamTwoTeamManager }}</span></td>
                            </tr>
                        </table>
                    </div>
                    <div class="pt-5 align-self-center" v-if="matchOfficials && matchOfficials.length">
                        <table class="w-100 no-border">
                            <tr v-for="(official, i) in matchOfficials" v-bind:key="i">
                                <td class="left-col">
                                    <span class="fieldKey">{{ $t(official.position) }}</span>
                                </td>
                                <td class="right-col">
                                    {{ official.name }} ({{ official.union }})
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </wrapper>
</template>

<script>
import actionButton from '@/components/actionButton.vue';
import wrapper from '@/components/wrapper.vue';
import base from '@/views/base.vue';
import { pascalCase } from '@/utils/miscUtils';

export default {
    name: 'detailsHeader',
    extends: base,
    components: {
        wrapper,
        actionButton,
    },
    props: {
        /**
         * wrapper title
         */
        title: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name
         */
        data: {
            type: Object,
            default: null,
        },
        teamOneHeadCoach: {
            type: String,
            required: false,
            default: null,
        },
        teamTwoHeadCoach: {
            type: String,
            required: false,
            default: null,
        },
        teamOneTeamDoctor: {
            type: String,
            required: false,
            default: null,
        },
        teamTwoTeamDoctor: {
            type: String,
            required: false,
            default: null,
        },
        teamOneTeamManager: {
            type: String,
            required: false,
            default: null,
        },
        teamTwoTeamManager: {
            type: String,
            required: false,
            default: null,
        },
        matchOfficials: {
            type: Array,
            default: null,
        },
        /**
         * item name sub text
         */
        nameSub: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name sub text 2
         */
        nameSub2: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image path (src attribute)
         */
        imagePath: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image title attribute
         */
        imageTitle: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image alt attribute
         */
        imageAlt: {
            type: String,
            required: false,
            default: null,
        },
        status: {
            type: String,
            required: false,
            default: null,
        },
        statusType: {
            type: String,
            required: false,
            default: '',
        },
        toBeDeletedOn: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * main detail data
         * array items must be objects with "label" (string) and "content" (string)
         */
        details: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        /**
         * more detail data
         * array items must be objects with "headline" (string) and "items" (array), which must contain objects with "label" (string) and "content" (string)
         */
        moreDetails: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            emptyString: '-----',
        };
    },
    methods: {
        getPersonForTeam(teamIndex, positionName) {
            if (teamIndex == null || !positionName) return this.emptyString;

            if (this.data.teams[teamIndex]?.players) {
                const person = this.data.teams[teamIndex]?.players.find(p => p.player?.roleName === 'Management' && p.player?.positionName === positionName);
                return person?.player?.displayName;
            }
            const teamName = `team${teamIndex === 0 ? 'One' : 'Two'}`;
            const positionAttribute = `${teamName}${pascalCase(positionName)}`;
            return this[positionAttribute] ? this[positionAttribute] : this.emptyString;
        }
    },
    computed: {
        itemClasses() {
            return 'mb-5 mx-3 mx-lg-0';
        },
        displayTeamOneHeadCoach() {
            return this.getPersonForTeam(0, 'Head Coach');
        },
        displayTeamTwoHeadCoach() {
            return this.getPersonForTeam(1, 'Head Coach');
        },
        displayTeamOneTeamDoctor() {
            return this.getPersonForTeam(0, 'Team Doctor');
        },
        displayTeamTwoTeamDoctor() {
            return this.getPersonForTeam(1, 'Team Doctor');
        },
        displayTeamOneTeamManager() {
            return this.getPersonForTeam(0, 'Team Manager');
        },
        displayTeamTwoTeamManager() {
            return this.getPersonForTeam(1, 'Team Manager');
        },
    },
}
</script>
