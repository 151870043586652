<template>
    <b-table class="m-0 teamsheetTable" v-bind:fields="tableFields" thead-class="d-none"
        :tbody-tr-class="'teamsheetRow'" v-bind:items="items" responsive hover>
        <template #cell(number)="data">
            {{ data.item.number }}
        </template>
        <template #cell(name)="data">
            <span class="no-wrap">{{ data.item.name }}</span>
        </template>
        <template #cell(date-of-birth)="data">
            <span v-bind:class="{'no-wrap': true, 'text-danger': data.item.isUnderage}">
                {{ data.item.dateOfBirth }}
            </span>
        </template>
        <template #cell(card)="data">
            <icon v-bind:type="'card-' + data.item.card" medium v-if="data.item.card" />
        </template>
        <template #cell(replacement)="data">
            <div class="center-icon">
                <icon
                v-bind:type="isSub ? 'up-circle' : 'down-circle'"
                v-bind:color="isSub ? 'success' : 'danger'"
                class="p-0"
                medium v-if="data.item.replacement"
            />
            </div>
        </template>
        <template #cell(scoring)="data">
            <icon type="ball" medium v-if="data.item.scoring" />
        </template>
    </b-table>
</template>

<script>
import icon from '@/components/icon.vue';
import { isUnderage, calculateAge } from '@/utils/dateUtils';

export default {
    name: 'teamsheetTable',
    components: {
        icon
    },
    props: {
        players: {
            type: Array,
            required: true,
        },
        isSub: {
            type: Boolean,
            required: false,
            default: false,
        },
        scorings: {
            type: Object,
            required: false
        },
        disciplinaries: {
            type: Object,
            required: false
        },
        matchKickOffDate: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: this.$t('lineup'),
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: '',
                    tdClass: 'match-teamsheet-name',
                },
                {
                    key: 'date-of-birth',
                    label: '',
                    tdClass: '',
                },
                {
                    key: 'captain',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'scoring',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'card',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'replacement',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
            ],
        }
    },
    methods: {
        formatTableData(players, isSub = false) {
            const map = {};
            for (let player of players) {
                const number = parseInt(player.positionNumber) || this.getDisplayNumber(player);
                if (!number) {
                    continue;
                }

                map[number] = player;
            }
            let formattedPlayers = Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player)
                .map((data) => {
                    return {
                        number: typeof data?.positionNumber === 'number' ? parseInt(data?.positionNumber) : this.getDisplayNumber(data),
                        name: this.formatName(data),
                        dateOfBirth: `${this.$fd(data.dateOfBirth, 'date')} (${calculateAge(data.dateOfBirth, this.matchKickOffDate)})`,
                        isUnderage: isUnderage(data.dateOfBirth, 19, this.matchKickOffDate),
                        captain: data?.getPlayerAttribute('isCaptain') ? '(c)' : '',
                        scoring: data?.eqId && this.scorings[data?.eqId],
                        card: ((data?.eqId && this.disciplinaries[data?.eqId]) ? this.disciplinaries[data?.eqId].cardType : null),
                        replacement: isSub ? data?.getPlayerAttribute('isUsedReplacement') : data.getPlayerAttribute('isReplaced'),
                    }
                });
            return formattedPlayers;
        },
        formatName(teamsheet) {
            if (!teamsheet) {
                return '';
            }

            let name = teamsheet.getName();

            return name;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                return '' + (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
            }

            if (person?.number) {
                return '' + person.number;
            }

            return '';
        },
    },

    computed: {
        items() {
            return this.formatTableData(this.players, this.isSub);
        }
    }
}
</script>
