<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('serviceName', 1)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('serviceName')" v-if="isHeadline">{{ getHeadline('serviceName', $t('serviceName')) }}</label>
            <span class="text-uppercase" v-else>{{ apiResourceMethod.label() }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('description', 12, 6, 4)">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('description')">{{ getHeadline('description', $t('description')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ apiResourceMethod.name }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('add', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', apiResourceMethod.label())"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-on:click="emitEvent('move', $event)"
                v-bind:to="moveTo"
                v-if="showMove"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', apiResourceMethod.label())"
            v-bind:text="$t('pleaseConfirmDeletion', { name: apiResourceMethod.label() })"
            v-bind:data="apiResourceMethod"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from '../base.vue';

import ApiResourceMethodModel from '@/models/apiResourceMethod';

/**
 * apiResourceMethod row component
 */
export default {
    name: 'apiResourceMethodRow',
    extends: base,
    props: {
        /**
         * apiResourceMethod object
         */
        apiResourceMethod: {
            type: ApiResourceMethodModel,
            default(){
                return new ApiResourceMethodModel();
            }
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },

        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        emitEventData(){
            return {
                apiResourceMethod: this.apiResourceMethod || null,
            };
        },
        hasVisibleCircles(){
            return (this.showAdd || this.showRemove || this.showMove);
        },
    },
};
</script>
