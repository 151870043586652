<template>
    <div>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-text>
            <div class="d-flex align-items-center" v-bind:class="{ invisible: this.isHeadline }">
                <div class="d-flex flex-column my-n1 mr-2" v-if="showRankIcon">
                    <icon v-bind:type="rankIconType" v-if="!rank.sameRank"/>
                    <template v-else>
                        <icon type="up"/>
                        <icon type="down" class="mt-n2"/>
                    </template>
                </div>
            </div>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('rank', 2, 'auto', 1)" class="mr-3 mr-lg-0" v-if="showRank">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('rank')" v-if="isHeadline">{{ getHeadline('rank', $t('rank')) }}</label>
            <span v-else>
                {{ rank.position }}
                <span class="ml-1" v-if="!rank.sameRank">({{ rank.previousPosition }})</span>
            </span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('teamCode', 12, 6, 1)" v-bind:title-tag="titleTag" v-if="showTeamCode">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamCode')">{{ getHeadline('teamCode', $tc('code', 1)) }}</label>
            <span v-if="!isHeadline">{{ rank.teamCode }}</span>
        </b-card-text>

        <b-card-title v-bind="getColumnProps('teamName', 3)" v-bind:title-tag="titleTag" v-if="showTeam">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('teamName')" v-if="isHeadline">{{ getHeadline('teamName', $tc('team', 1)) }}</label>
            <span v-else>{{ rank.teamName }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('points', 12, 6, 2)" v-if="showPoints">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('points')">{{ getHeadline('points', $tc('point', 2)) }}</label>
            <span v-if="!isHeadline" v-b-tooltip.hover.top="rank.points">{{ $n(rank.points) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('previousPoints', 12, 6, 2)" v-if="showPreviousPoints">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('previousPoints')">{{ getHeadline('previousPoints', $t('previousPoints')) }}</label>
            <span v-if="!isHeadline" v-b-tooltip.hover.top="rank.previousPoints">{{ $n(rank.previousPoints) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('plusMinus', 12, 6, 2)" v-if="showPlusMinus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('plusMinus')">{{ getHeadline('plusMinus', $t('plusMinus')) }}</label>
            <span v-if="!isHeadline">{{ rank.position }} / {{ rank.previousPosition }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null,true)" class="align-self-start align-self-lg-center text-right" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-if="showMatchesToggle"
                v-b-toggle="'accordion-' + rank.teamEqId"
                v-bind:class="{ invisible: !runMatchesItems.length }"
            >
                <icon v-bind:type="runMatchesOpen ? 'up-circle-full' : 'down-circle-full'" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>
    </b-card>
    <b-collapse
        v-model="runMatchesOpen"
        v-bind:id="'accordion-' + rank.teamEqId"
        class="overflow-auto mb-3"
        accordion="runMatches"
        role="tabpanel"
        v-if="rank && !isHeadline && showMatchesToggle && runMatchesItems.length"
    >
        <b-table
            v-bind:items="runMatchesItems"
            v-bind:fields="runMatchesFields"
            head-variant="light"
            class="mb-2"
            hover
        >
            <template #cell(home)="data">
                <icon v-bind:type="data.value ? 'confirm-circle-full' : 'cancel'"/>
            </template>
            <template #cell(link)="data">
                <actionButton
                    openInNewTab
                    variant="icon"
                    v-bind:to="{ name: 'commonMatchDetail', params: { dataMatchId: data.item.matchId } }"
                    v-if="data.item.matchId"
                >
                    <icon type="view"/>
                </actionButton>
            </template>
        </b-table>
    </b-collapse>
    </div>
</template>

<script>
import base from './base.vue';

import RankingDataModel from '@/models/rankingData.js';

/**
 * ranking block component
 */
export default {
    name: 'rankingRow',
    extends: base,
    props: {
        rank: {
            type: RankingDataModel,
            default(){
                return new RankingDataModel();
            }
        },

        showRankIcon: {
            type: Boolean,
            default: false,
        },
        showRank: {
            type: Boolean,
            default: true,
        },
        showTeamCode: {
            type: Boolean,
            default: false,
        },
        showTeam: {
            type: Boolean,
            default: true,
        },
        showPoints: {
            type: Boolean,
            default: false,
        },
        showPreviousPoints: {
            type: Boolean,
            default: false,
        },
        showPlusMinus: {
            type: Boolean,
            default: false,
        },
        showMatchesToggle: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return {
            runMatchesOpen: false,
        };
    },
    computed: {
        customElementClasses(){
            const classes = {};

            classes[this.rowColor] = true;

            return classes;
        },
        rankIconType(){
            const iconType = '';
            if (this.rank.positionDifference !== 0) {
                return this.rank.positionDifference > 0 ? 'up' : 'down';
            }
            return iconType;
        },
        rowColor(){
            if (this.rank.positionDifference !== 0 && !this.isHeadline) {
                return this.rank.positionDifference > 0 ? 'text-success' : 'text-danger';
            }
            return '';
        },
        positionChange(){
            if (this.rank.positionDifference === 0) {
                return '-';
            }
            return this.rank.positionDifference > 0 ? '+ ' + this.rank.positionDifference : '- ' + Math.abs(this.rank.positionDifference);
        },
        hasVisibleCircles(){
            return this.showMatchesToggle;
        },
        runMatchesFields(){
            return [
                {
                    key: 'date',
                    label: this.$t('date'),
                },
                {
                    key: 'tournament',
                    label: this.$tc('tournament', 1),
                },
                {
                    key: 'home',
                    label: this.$t('home'),
                },
                {
                    key: 'opponent',
                    label: this.$tc('opponent', 1),
                },
                {
                    key: 'score',
                    label: this.$t('score'),
                },
                {
                    key: 'result',
                    label: this.$t('result'),
                },
                {
                    key: 'ptsBefore',
                    label: this.$t('ptsBefore'),
                },
                {
                    key: 'ptsAfter',
                    label: this.$t('ptsAfter'),
                },
                {
                    key: 'eventMultiplier',
                    label: this.$t('eventMultiplierAbbr'),
                },
                {
                    key: 'winMultiplier',
                    label: this.$t('winMultiplierAbbr'),
                },
                {
                    key: 'link',
                    label: '',
                    class: 'd-print-none',
                },
            ];
        },
        runMatchesItems(){
            return (this.rank.runMatches || []).map(runMatch => {
                const teamNr = (this.rank.teamEqId === runMatch.team1EqId ? 1 : 2);

                return {
                    date: this.$fd(runMatch.match.kickOff, 'dateTime'),
                    tournament: runMatch.match.tournaments.map(tournament => tournament.label()).join(', '),
                    home: runMatch[`team${teamNr}IsAtHome`],
                    opponent: runMatch[`team${teamNr % 2 + 1}Code`] || runMatch.match['team' + (teamNr === 1 ? 'Two' : 'One')]?.name,
                    score: runMatch[`team${teamNr}Score`],
                    result: runMatch[`team${teamNr}Result`],
                    ptsBefore: runMatch[`team${teamNr}PointsBefore`],
                    ptsAfter: runMatch[`team${teamNr}Points`],
                    eventMultiplier: runMatch.matchMultiplier,
                    winMultiplier: runMatch.winMultiplier,
                    matchId: runMatch.match.id,
                };
            });
        },
    },
};
</script>
