<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <!-- <div v-bind="getColumnProps(null, 1, null, null, true)" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="series.getImgPath()"
                v-bind:alt="series.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div> -->

        <b-card-title v-bind="getColumnProps('seriesName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('seriesName')" v-if="isHeadline">{{ getHeadline('seriesName', $t('seriesName')) }}</label>
            <span class="text-uppercase" v-else>{{ series.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 1)" v-if="showStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $t('status')) }}</label>
            <span v-if="!isHeadline">{{ series.status }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('deadline', 12, 6, 2)" v-if="showDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('deadline')">{{ getHeadline('deadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ $fd(series.deadline) }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 2)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ series.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('publishStatus', 12, 6, 2)" v-if="showPublishStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('publishStatus')">{{ getHeadline('publishStatus', $t('publishStatus')) }}</label>
            <publishStatusIndicator v-bind:type="series.publishStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('mainSeason', 12, 6, 2)" v-if="showMainSeason">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('mainSeason')">{{ getHeadline('mainSeason', $t('mainYear')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ series.mainSeason }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowStatus', 12, 6, 2)" v-if="showWorkflowStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowStatus')">{{ getHeadline('workflowStatus', $t('status')) }}</label>
            <statusIndicator v-bind:type="series.workflowStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowDeadline', 12, 6, 2)" v-if="showWorkflowDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowDeadline')">{{ getHeadline('workflowDeadline', $t('deadline')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ series.workflowDeadline }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '../status-indicator.vue';
import publishStatusIndicator from '../publish-status-indicator.vue';

import SeriesModel from '@/models/series.js';

/**
 * match block component
 */
export default {
    name: 'seriesRow',
    extends: base,
    components: {
        statusIndicator,
        publishStatusIndicator,
    },
    props: {
        series: {
            type: SeriesModel,
            default(){
                return new SeriesModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        showPublishStatus: {
            type: Boolean,
            default: false,
        },
        showDeadline: {
            type: Boolean,
            default: false,
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showMainSeason: {
            type: Boolean,
            default: false,
        },
        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },

        showDetail: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonSeriesDetail',
                params: Object.assign({
                    dataSeriesId: this.series.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                series: this.series || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
