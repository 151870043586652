export const calculateAge = (dateOfBirth, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const diff = new Date(comparisonDate.getTime() - dateOfBirth.getTime());
    return Math.abs(diff.getUTCFullYear() - 1970);
}

export const isUnderage = (dateOfBirth, threshhold = 19, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const age = calculateAge(dateOfBirth, comparisonDate);

    return age < threshhold;
}
