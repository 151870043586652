<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('text', 1)" v-bind:title-tag="titleTag">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('text')" class="text-center" v-if="isHeadline">{{ getHeadline('text', '') }}</label>
            <span v-bind:class="textClasses" v-else>{{ text }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('add', $event)"
                v-if="showAdd"
            >
                <icon type="add" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:openInNewTab="openInNewTab"
                v-on:click="emitEvent('move', $event)"
                v-bind:to="moveTo"
                v-if="showMove"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 3)" class="flex-break d-lg-none"></div>
    </b-card>
</template>

<script>
import base from './base.vue';

/**
 * simple row component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'simpleRow',
    extends: base,
    props: {
        /**
         * text to show
         */
        text: {
            type: String,
            default: null,
        },
        /**
         * show text to the left instead of centered
         */
        textLeft: {
            type: Boolean,
            default: false,
        },
        /**
         * use normal colors for text
         */
        normalText: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with add icon
         * clicking it will trigger "add" event
         */
        showAdd: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with remove icon
         * clicking it will trigger "remove" event
         */
        showRemove: {
            type: Boolean,
            default: false,
        },
        /**
         * show button with arrow icon
         * clicking it will trigger "move" event
         */
        showMove: {
            type: Boolean,
            default: false,
        },
        /**
         * to location for move button
         */
        moveTo: {
            type: [String, Object],
            default: null,
        },
        openInNewTab: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasVisibleCircles() {
            return (this.showAdd || this.showRemove || this.showMove);
        },
        textClasses() {
            return {
                'd-block': true,
                'text-center': !this.textLeft,
                'text-secondary': !this.normalText,
            };
        },
    },
};
</script>
