<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('tournamentTypeName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label
                v-bind:class="headlineClasses"
                v-on:click="handleHeadlineSort('tournamentTypeName')"
                v-if="isHeadline"
            >
                    {{ getHeadline('tournamentTypeName', $t('name')) }}
            </label>
            <span v-else>{{ tournamentType.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('tournamentGroup', 12, 6, 2)" v-if="showTournamentGroup">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('tournamentGroup')">{{ getHeadline('tournamentGroup', $tc('tournamentGroup', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentType.tournamentTypeGroup ? tournamentType.tournamentTypeGroup.name : '' }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('participants', 12, 6, 2)" v-if="showParticipants">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('participants')">{{ getHeadline('participants', $tc('participant', 2)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentType.numberOfParticipants }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('radarThreeSixtyName', 12, 6, 2)" v-if="showRadarThreeSixtyName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('radarThreeSixtyName')">{{ getHeadline('radarThreeSixtyName', $tc('radarThreeSixtyName', 2)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentType.radarThreeSixtyName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 2, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentType.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>

            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentType.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', tournamentType.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: tournamentType.name })"
            v-bind:data="tournamentType"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from './../base.vue';

import TournamentTypeModel from '@/models/tournamentType.js';

/**
 * tournamentType block component
 */
export default {
    name: 'tournamentTypeRow',
    extends: base,
    props: {
        tournamentType: {
            type: TournamentTypeModel,
            required: false,
            default(){
                return new TournamentTypeModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showTournamentGroup: {
            type: Boolean,
            default: false,
        },
        showParticipants: {
            type: Boolean,
            default: false,
        },
        showRadarThreeSixtyName: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: 'administrationTournamentTypeDetail',
                params: Object.assign({
                    dataTournamentTypeId: this.tournamentType.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournamentType: this.tournamentType || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove || this.showDelete);
        },
    },
};
</script>
