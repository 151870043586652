<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true, { hasImg: showImg, noName: !showName, })" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="team.getImgPath()"
                v-bind:alt="team.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('name', 2)" v-bind:title-tag="titleTag" v-if="showName && !showShortName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('name')" v-if="isHeadline">{{ getHeadline('name', $t('teamName')) }}</label>
            <span v-else>{{ team.getName() }}</span>
        </b-card-title>

        <b-card-title v-bind="getColumnProps('shortName', 2)" v-bind:title-tag="titleTag" v-if="showShortName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('shortName')" v-if="isHeadline">{{ getHeadline('shortName', $t('teamNameShort')) }}</label>
            <span v-else>{{ team.getName() }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('status', 12, 6, 2)" v-if="showTournamentTeamStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('status')">{{ getHeadline('status', $tc('status', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.tournamentTeamStatus }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('core', 12, 6, 2)" v-if="showCoreTeamCheckbox">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('core')">{{ getHeadline('core', $t('coreTeam')) }}</label>
            <b-form-checkbox v-bind:checked="team.isTourMember" disabled v-if="!isHeadline"></b-form-checkbox>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 2)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('type', 12, 6, 2)" v-if="showType">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('type')">{{ getHeadline('type', $t('teamType')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.teamType }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('country', 12, 6, 2)" v-if="showCountry">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('country')">{{ getHeadline('country', $t('country')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.countryName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('union', 12, 6, 2)" v-if="showUnion">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('union')">{{ getHeadline('union', $tc('union', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.unionName }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('points', 12, 6, 2)" v-if="showPoints">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('points')">{{ getHeadline('points', $tc('point', 2)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ team.points }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowStatus', 12, 6, 2)" v-if="showWorkflowStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowStatus')">{{ getHeadline('workflowStatus', '') }}</label>
            <statusIndicator v-bind:type="team.workflowStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowDeadline', 12, 6, 2)" v-if="showWorkflowDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowDeadline')">{{ getHeadline('workflowDeadline', '') }}</label>
            <span v-if="!isHeadline">{{ team.workflowDeadline }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('select', 3, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="showSelect">
            <actionButton
                variant="outline-primary"
                v-bind="buttonAttributes"
                v-on:click="emitEvent('select', $event)"
            >
                {{ $t('select') }}
            </actionButton>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 4, null, null, true)" class="align-self-start align-self-lg-center text-right" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-b-modal="modalName('editTeamEventSeriesPoints', team.id || team.tournamentTeamId)"
                v-if="showPoints"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-if="showRemove"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', team.id || team.tournamentTeamId)"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 5)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', team.id || team.tournamentTeamId)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: team.name })"
            v-bind:data="team"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

        <editTeamEventSeriesPointsModal
            v-bind:id="modalName('editTeamEventSeriesPoints', team.id || team.tournamentTeamId)"
            v-bind:team="team"
            v-bind:title="$t('editEventSeriesPoints')"
            v-bind:confirmHandler="editTeamEventSeriesPointsHandler"
            v-on:confirmed="emitEvent('editTeamEventSeriesPointsUpdated')"
            v-if="showPoints"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '../status-indicator.vue';

import TeamModel from '@/models/team.js';
import editTeamEventSeriesPointsModal from '@/components/modals/editTeamEventSeriesPoints.vue';

/**
 * team block component
 */
export default {
    name: 'teamRow',
    extends: base,
    components: {
        statusIndicator,
        editTeamEventSeriesPointsModal,
    },
    props: {
        team: {
            type: TeamModel,
            required: false,
            default(){
                return new TeamModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showShortName: {
            type: Boolean,
            default: false,
        },
        showTournamentTeamStatus: {
            type: Boolean,
            default: false,
        },
        showCoreTeamCheckbox: {
            type: Boolean,
            default: false,
        },
        showSport: {
            type: Boolean,
            default: false,
        },
        showType: {
            type: Boolean,
            default: false,
        },
        showCountry: {
            type: Boolean,
            default: false,
        },
        showUnion: {
            type: Boolean,
            default: false,
        },
        showPoints: {
            type: Boolean,
            default: false,
        },

        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },

        showRemove: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },

        /**
         * show button to select item (used for picker)
         */
        showSelect: {
            type: Boolean,
            default: false,
        },

        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },

        /**
         * edit Team Event Series Points handler function that returns a Promise
         */
        editTeamEventSeriesPointsHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonTeamDetail',
                params: Object.assign({
                    dataTeamId: this.team.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                team: this.team || null,
            };
        },
        customElementClasses(){
            return {
                teamRow: true,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showRemove || this.showPoints);
        },
    },
};
</script>
