<template>
    <b-button
        v-bind:variant="variant || buttonVariant"
        v-bind:type="type || 'button'"
        v-bind:disabled="disabled || loading"
        v-bind:to="to"
        v-bind:href="href"
        v-on:click="$emit('click', $event)"
        v-if="isAllowed"
        v-bind:target="navTarget"
    >
        <throbber small class="align-middle mr-1 mb-1" v-if="showLoadingThrobber && loading" />
        <slot />
    </b-button>
</template>

<script>
import throbber from '@/components/throbber.vue';
import PermissionModel from '@/models/permission.js';
import { empty } from '@/utils/miscUtils';

export default {
    name: 'actionButton',
    components: {
        throbber,
    },
    props: {
        /**
         * button type
         */
        type: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * button variant
         */
        variant: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * promise during which button is shown as loading
         */
        loadingWhile: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * show throbber during loading state (enabled by default for type "submit")
         */
        loadingThrobber: {
            type: Boolean,
            required: false,
            default: null,
        },
        /**
         * button routing to
         */
        to: {
            type: [Object, String],
            required: false,
            default: null,
        },
        /**
         * button href
         */
        href: {
            type: [Object, String],
            required: false,
            default: null,
        },
        /**
         * permission check requirement
         */
        permission: {
            type: PermissionModel,
            required: false,
            default: undefined,
        },
        /**
         * button disabled
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        openInNewTab: {
            type: Boolean,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            loading: false,
            waitingFor: null,
        };
    },
    methods: {
        loadingWhilePromise(promise) {
            //if no promise given, set as not loading
            if (promise === null) {
                this.loading = false;
                this.waitingFor = null;

                return;
            }

            //if promise given, wait for it
            this.loading = true;
            this.waitingFor = promise;

            //waiting...
            promise.then(result => result, error => error).then(() => {
                //make sure the promise did not change in the meantime
                if (this.waitingFor !== promise) {
                    return;
                }

                this.loading = false;
                this.waitingFor = null;
            });
        },
    },
    computed: {
        buttonVariant() {
            switch (this.type) {
                case 'submit':
                    return 'success';

                default:
                    return 'secondary';
            }
        },
        showLoadingThrobber() {
            return (this.loadingThrobber || (this.type === 'submit' && this.loadingThrobber !== false));
        },
        isAllowed() {
            return (
                (!this.permission || this.$areAllowed(this.permission.getWithChildren())) &&
                (!this.to || this.$isRouteAllowed(this.to))
            );
        },
        navTarget() {
            if (!empty(this.openInNewTab) && this.openInNewTab) {
                return '_blank';
            }

            return '_self';
        }
    },
    watch: {
        loadingWhile(promise) {
            this.loadingWhilePromise(promise);
        },
    },
    created() {
        this.loadingWhilePromise(this.loadingWhile);
    },
    destroyed() {
        this.loadingWhilePromise(null);
    },
}
</script>
