<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <div v-bind="getColumnProps(null, 1, null, null, true)" class="card-img-wrapper" v-if="showImg">
            <b-card-img
                v-bind="getColumnProps('image')"
                v-bind:src="union.getImgPath()"
                v-bind:alt="union.name"
                v-bind:width="imgWidth"
                v-bind:height="imgHeight"
            />
        </div>

        <b-card-title v-bind="getColumnProps('unionName', 2)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('unionName')" v-if="isHeadline">{{ getHeadline('unionName', $t('unionName')) }}</label>
            <span class="text-uppercase" v-else>{{ union.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('association', 12, 6, 3)" v-if="showRegionalAssociation">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('association')">{{ getHeadline('association', $t('regionalAssociation')) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ union.unionRegion }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowStatus', 12, 6, 2)" v-if="showWorkflowStatus">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowStatus')">{{ getHeadline('workflowStatus', $t('status')) }}</label>
            <statusIndicator v-bind:type="union.workflowStatus" v-if="!isHeadline"/>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('workflowDeadline', 12, 6, 2)" v-if="showWorkflowDeadline">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('workflowDeadline')">{{ getHeadline('workflowDeadline', $t('deadline')) }}</label>
            <span v-if="!isHeadline">{{ union.workflowDeadline }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 3, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-if="showRemove"
                v-on:click="emitEvent('remove', $event)"
                v-b-modal="modalName('confirmDelete', union.id)"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', union.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: union.name })"
            v-bind:data="union"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />
    </b-card>
</template>

<script>
import base from './base.vue';
import statusIndicator from '../status-indicator.vue';

import UnionModel from '@/models/union.js';

/**
 * union block component
 */
export default {
    name: 'unionRow',
    extends: base,
    components: {
        statusIndicator,
    },
    props: {
        union: {
            type: UnionModel,
            default(){
                return new UnionModel();
            }
        },

        showImg: {
            type: Boolean,
            default: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        showRegionalAssociation: {
            type: Boolean,
            default: false,
        },
        showWorkflowStatus: {
            type: Boolean,
            default: false,
        },
        showWorkflowDeadline: {
            type: Boolean,
            default: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        imgWidth: {
            type: Number,
            default: 32,
        },
        imgHeight: {
            type: Number,
            default: 32,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        detailLink(){
            return {
                name: this.detailLinkRoute || 'commonUnionDetail',
                params: Object.assign({
                    dataUnionId: this.union.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                union: this.union || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail);
        },
    },
};
</script>
