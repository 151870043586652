<template>
    <b-card
        v-bind="rowAttributes"
        v-on:click="emitEvent('click', $event)"
    >
        <b-card-title v-bind="getColumnProps('tournamentTypeGroupName', 1)" v-bind:title-tag="titleTag" v-if="showName">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('locationName')" v-if="isHeadline">{{ getHeadline('tournamentTypeGroupName', $t('name')) }}</label>
            <span v-else>{{ tournamentTypeGroup.name }}</span>
        </b-card-title>

        <b-card-text v-bind="getColumnProps('sport', 12, 6, 2)" v-if="showSport">
            <label v-bind:class="headlineClasses" v-on:click="handleHeadlineSort('sport')">{{ getHeadline('sport', $tc('sport', 1)) }}</label>
            <span class="text-uppercase" v-if="!isHeadline">{{ tournamentTypeGroup.sport }}</span>
        </b-card-text>

        <b-card-text v-bind="getColumnProps('circles', 3, null, null, true)" class="align-self-start align-self-lg-center text-right no-mobile-line" v-if="hasVisibleCircles">
            <actionButton
                variant="icon"
                openInNewTab
                v-bind="buttonAttributes"
                v-bind:to="detailLink"
                v-if="showDetail"
            >
                <icon type="view" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="editPermission"
                v-bind:to="editLink"
                v-if="showEdit"
            >
                <icon type="edit-circle-full" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentTypeGroup.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showRemove"
            >
                <icon type="remove" large/>
            </actionButton>
            <actionButton
                variant="icon"
                v-bind="buttonAttributes"
                v-bind:permission="removePermission"
                v-b-modal="modalName('confirmDelete', tournamentTypeGroup.id)"
                v-on:click="emitEvent('remove', $event)"
                v-if="showDelete"
            >
                <icon type="cancel-circle-full" large/>
            </actionButton>
        </b-card-text>

        <div v-bind="getColumnProps(null, 4)" class="flex-break d-lg-none"></div>

        <confirmModal
            v-bind:id="modalName('confirmDelete', tournamentTypeGroup.id)"
            v-bind:text="$t('pleaseConfirmDeletion', { name: tournamentTypeGroup.name })"
            v-bind:data="tournamentTypeGroup"
            v-bind:confirmHandler="removeHandler"
            v-on:confirmed="emitEvent('removed')"
        />

    </b-card>
</template>

<script>
import base from './../base.vue';

import TournamentTypeGroupModel from '@/models/tournamentTypeGroup.js';

/**
 * tournamentTypeGroup block component
 */
export default {
    name: 'tournamentTypeGroupRow',
    extends: base,
    props: {
        tournamentTypeGroup: {
            type: TournamentTypeGroupModel,
            required: false,
            default(){
                return new TournamentTypeGroupModel();
            }
        },

        showName: {
            type: Boolean,
            default: true,
        },
        showSport: {
            type: Boolean,
            default: true,
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        showRemove: {
            type: Boolean,
            default: false,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
        removeHandler: {
            type: Function,
            default: null,
        },
    },
    computed: {
        editLink(){
            return {
                name: 'administrationTournamentTypeGroupEdit',
                params: Object.assign({
                    dataTournamentTypeGroupId: this.tournamentTypeGroup.id,
                }, this.detailLinkParams),
            };
        },
        emitEventData(){
            return {
                tournamentTypeGroup: this.tournamentTypeGroup || null,
            };
        },
        hasVisibleCircles(){
            return (this.showDetail || this.showEdit || this.showDelete || this.showRemove);
        },
    },
};
</script>
