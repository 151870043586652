<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle" /> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            v-bind:title="$t('teamsheets')"
            v-bind:data="teamsheets"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
            v-bind:teamOneTeamDoctor="teamOneTeamDoctor"
            v-bind:teamTwoTeamDoctor="teamTwoTeamDoctor"
            v-bind:teamOneTeamManager="teamOneTeamManager"
            v-bind:teamTwoTeamManager="teamTwoTeamManager"
            v-bind:matchOfficials="matchOfficials"
        />
        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow  waitSilent
        >
            <h2 class="text-center mb-5 mt-5">{{ $t('lineup') }}</h2>
            <div class="d-flex flex-row justify-content-between">
                <teamsheetTable
                    v-bind:players="team1Lineup"
                    v-bind:isSub="false"
                    v-bind:scorings="team1Scorings"
                    v-bind:disciplinaries="team1Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
                <teamsheetTable
                    v-bind:players="team2Lineup"
                    v-bind:isSub="false"
                    v-bind:scorings="team2Scorings"
                    v-bind:disciplinaries="team2Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
            </div>

            <h2 class="text-center mb-5 mt-5">{{ $t('subs') }}</h2>
            <div class="d-flex flex-row justify-content-between">
                <teamsheetTable
                    v-bind:players="team1Subs"
                    v-bind:isSub="true"
                    v-bind:scorings="team1Scorings"
                    v-bind:disciplinaries="team1Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
                <teamsheetTable
                    v-bind:players="team2Subs"
                    v-bind:isSub="true"
                    v-bind:scorings="team2Scorings"
                    v-bind:disciplinaries="team2Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
            </div>
        </wrapper>
    </div>
</template>

<script>
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import teamsheetTable from '@/components/tables/TeamsheetTable.vue';
import MatchTeamsheetModel from '@/models/matchTeamsheet.js';
import base from '@/views/base.vue';

export default {
    name: 'commonMatchTeamsheets',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
        teamsheetTable
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                teamsheets: this.loadTeamsheets(),
            },
            matchTournaments: [],
            matchOfficials: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            teamOneTeamDoctor: '',
            teamTwoTeamDoctor: '',
            teamOneTeamManager: '',
            teamTwoTeamManager: '',
            match: null,
        };
    },
    computed: {
        backToMatchDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        team1Lineup() {
            return this.getLineup(0);
        },
        team2Lineup() {
            return this.getLineup(1);
        },
        team1Subs() {
            return this.getLineup(0, true);
        },
        team2Subs() {
            return this.getLineup(1, true);
        },
        team1Disciplinaries() {
            return this.getDisciplinaries(0);
        },
        team2Disciplinaries() {
            return this.getDisciplinaries(1);
        },
        team1Scorings() {
            return this.getScorings(0);
        },
        team2Scorings() {
            return this.getScorings(0);
        }
    },
    methods: {
        loadTeamsheets() {
            return this.$api.call.match.matchReportsTeamsheetsGetByMatchId(this.dataMatchId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);

                this.teamOneHeadCoach = this.extractTeamPersonnel(0, 'Head Coach');
                this.teamTwoHeadCoach = this.extractTeamPersonnel(1, 'Head Coach');

                this.teamOneTeamDoctor = this.extractTeamPersonnel(0, 'Team Doctor');
                this.teamTwoTeamDoctor = this.extractTeamPersonnel(1, 'Team Doctor');

                this.teamOneTeamManager = this.extractTeamPersonnel(0, 'Team Manager');
                this.teamTwoTeamManager = this.extractTeamPersonnel(1, 'Team Manager');

                this.match = this.teamsheets.match;

                return this.getMatchOfficials();
            });
        },
        reloadTeamsheets() {
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            return this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                    union: official.unionName,
                }));
            });
        },
        extractTeamPersonnel(teamIndex, positionName) {
            const teamPerson = this.teamsheets.teams[teamIndex].team.teamSheets.filter((item) => item.positionName === positionName);
            return teamPerson[0]?.displayName || '';
        },
        getLineup(teamIndex, isSubs = false) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return [];
            }

            return this.teamsheets.teams[teamIndex].team.teamSheets.filter((item) =>
                isSubs ? item.positionName === 'Replacement' : item.positionName !== 'Replacement');
        },
        getDisciplinaries(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }

            const map = {};
            const disciplinaryData = this.teamsheets.teams[teamIndex].team.disciplinary;
            ['yellow', 'red'].forEach(cardType => {
                const disciplinaryList = disciplinaryData[cardType + 'Cards'] || [];

                disciplinaryList.forEach(disciplinary => {
                    if (!disciplinary.personEqId) {
                        return;
                    }

                    disciplinary.cardType = cardType;
                    map[disciplinary.personEqId] = disciplinary;
                });
            });
            return map;
        },
        getScorings(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }

            const map = {};
            const scoreData = this.teamsheets.teams[teamIndex].team.scoring;
            ['conversion', 'tries', 'penalties', 'dropGoals'].forEach(scoreType => {
                const scoreList = scoreData[scoreType] || [];

                scoreList.forEach(score => {
                    if (!score.personEqId || !score.points) {
                        return;
                    }

                    map[score.personEqId] = true;
                });
            });

            return map;
        }
    },
};
</script>
